import { conform, useForm } from "@conform-to/react";
import { getFieldsetConstraint, parse } from "@conform-to/zod";
import { json, redirect } from "@remix-run/node";
import type {
  LoaderFunctionArgs,
  ActionFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { safeRedirect } from "remix-utils/safe-redirect";
import { z } from "zod";
import { prisma } from "~/utils/db.server";
import { twoFAVerificationType } from "../two-factor";
import { verifySessionStorage } from "~/utils/verification.server";
import type { VerifyFunctionArgs } from "./verify";
import { getRedirectToUrl } from "./verify";
import { combineResponseInits, redirectWithToast } from "~/utils/misc.server";
import { authSessionStorage } from "~/utils/model-utils/session.server";
import {
  getUserId,
  login,
  requireAnonymous,
  sessionKey,
} from "~/utils/auth-utils/auth.server";
import invariant from "tiny-invariant";
import {
  EmailSchema,
  PasswordSchema,
} from "~/utils/model-utils/user-validation";
import { validateCSRF } from "~/utils/csrf.server";
import { useIsPending } from "~/utils/misc";
import { CheckboxField, ErrorList } from "~/components/ui/Forms";
import { Button } from "~/components/ui/Button";
import { honeypot } from "~/utils/honeypot.server";
import { SpamError } from "remix-utils/honeypot/server";
import { Input } from "~/components/ui/Input";
import { Label } from "~/components/ui/Label";

const verifiedTimeKey = "verified-time";
const unverifiedSessionIdKey = "unverified-session-id";
const rememberKey = "remember";

export const meta: MetaFunction = () => {
  return [
    { title: "Login to Ranger" },
    {
      property: "og:title",
      content: "Login to Ranger",
    },
    {
      name: "description",
      content:
        "Ranger is a budgeting app designed to be as frictionless as possible. Understand what you’re spending, save the money you want, and organize your finances without fear.",
    },
  ];
};

export async function handleNewSession(
  {
    request,
    session,
    redirectTo,
    remember,
  }: {
    request: Request;
    session: { userId: string; id: string; expirationDate: Date };
    redirectTo?: string;
    remember: boolean;
  },
  responseInit?: ResponseInit,
) {
  const verification = await prisma.verification.findUnique({
    select: { id: true },
    where: {
      target_type: { target: session.userId, type: twoFAVerificationType },
    },
  });
  const userHasTwoFactor = Boolean(verification);

  if (userHasTwoFactor) {
    const verifySession = await verifySessionStorage.getSession();
    verifySession.set(unverifiedSessionIdKey, session.id);
    verifySession.set(rememberKey, remember);
    const redirectUrl = getRedirectToUrl({
      request,
      type: twoFAVerificationType,
      target: session.userId,
      redirectTo,
    });
    return redirect(
      `${redirectUrl.pathname}?${redirectUrl.searchParams}`,
      combineResponseInits(
        {
          headers: {
            "set-cookie":
              await verifySessionStorage.commitSession(verifySession),
          },
        },
        responseInit,
      ),
    );
  } else {
    const authSession = await authSessionStorage.getSession(
      request.headers.get("cookie"),
    );
    authSession.set(sessionKey, session.id);

    return redirect(
      safeRedirect(redirectTo),
      combineResponseInits(
        {
          headers: {
            "set-cookie": await authSessionStorage.commitSession(authSession, {
              expires: remember ? session.expirationDate : undefined,
            }),
          },
        },
        responseInit,
      ),
    );
  }
}

export async function handleVerification({
  request,
  submission,
}: VerifyFunctionArgs) {
  invariant(submission.value, "Submission should have a value by this point");
  const authSession = await authSessionStorage.getSession(
    request.headers.get("cookie"),
  );
  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );

  const remember = verifySession.get(rememberKey);
  const { redirectTo } = submission.value;
  const headers = new Headers();
  authSession.set(verifiedTimeKey, Date.now());

  const unverifiedSessionId = verifySession.get(unverifiedSessionIdKey);
  if (unverifiedSessionId) {
    const session = await prisma.session.findUnique({
      select: { expirationDate: true },
      where: { id: unverifiedSessionId },
    });
    if (!session) {
      throw await redirectWithToast(
        request,
        "/login",
        "Could not find session to verify. Please try again.",
      );
    }
    authSession.set(sessionKey, unverifiedSessionId);

    headers.append(
      "set-cookie",
      await authSessionStorage.commitSession(authSession, {
        expires: remember ? session.expirationDate : undefined,
      }),
    );
  } else {
    headers.append(
      "set-cookie",
      await authSessionStorage.commitSession(authSession),
    );
  }

  headers.append(
    "set-cookie",
    await verifySessionStorage.destroySession(verifySession),
  );

  return redirect(safeRedirect(redirectTo), { headers });
}

export async function shouldRequestTwoFA(request: Request) {
  const authSession = await authSessionStorage.getSession(
    request.headers.get("cookie"),
  );
  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );
  if (verifySession.has(unverifiedSessionIdKey)) return true;
  const userId = await getUserId(request);
  if (!userId) return false;
  // if it's over two hours since they last verified, we should request 2FA again
  const userHasTwoFA = await prisma.verification.findUnique({
    select: { id: true },
    where: { target_type: { target: userId, type: twoFAVerificationType } },
  });
  if (!userHasTwoFA) return false;
  const verifiedTime = authSession.get(verifiedTimeKey) ?? new Date(0);
  const twoHours = 1000 * 60 * 2;
  return Date.now() - verifiedTime > twoHours;
}

const LoginFormSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
  redirectTo: z.string().optional(),
  remember: z.boolean().optional(),
});

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request);
  return json({});
}

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request);
  const formData = await request.formData();
  let caughtByHoneypot = false;
  // Honeypot check
  try {
    honeypot.check(formData);
  } catch (error) {
    if (error instanceof SpamError) {
      caughtByHoneypot = true;
    } else {
      throw error;
    }
  }

  await validateCSRF(formData, request.headers);
  const submission = await parse(formData, {
    schema: (intent) =>
      LoginFormSchema.transform(async (data, ctx) => {
        if (intent !== "submit") return { ...data, session: null };

        if (caughtByHoneypot) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid sweetener",
          });
          return z.NEVER;
        }

        const session = await login(data);
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid username or password",
          });
          return z.NEVER;
        }

        return { ...data, session };
      }),
    async: true,
  });
  // get the password off the payload that's sent back
  delete submission.payload.password;

  if (submission.intent !== "submit") {
    // @ts-expect-error - conform should probably have support for doing this
    delete submission.value?.password;
    return json({ status: "idle", submission } as const);
  }
  if (!submission.value?.session) {
    return json({ status: "error", submission } as const, { status: 400 });
  }

  const { session, remember, redirectTo } = submission.value;

  return handleNewSession({
    request,
    session,
    remember: remember ?? false,
    redirectTo,
  });
}

export default function LoginPage() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getFieldsetConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastSubmission: actionData?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: LoginFormSchema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <div className="flex min-h-full flex-col justify-center pb-32 pt-20">
      <div className="mx-auto flex w-full max-w-md flex-col gap-8 lg:gap-12">
        <div className="flex flex-col gap-3 text-center">
          <p className="text-2xl font-semibold lg:text-4xl">Welcome Back!</p>
          <p className="text-base text-muted-foreground">
            Enter your details to continue.
          </p>
        </div>

        <div>
          <div className="mx-auto w-full max-w-md px-8">
            <Form method="POST" {...form.props} className="space-y-6">
              <AuthenticityTokenInput />
              <HoneypotInputs />
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input type="email" {...conform.input(fields.email)} />
                {fields.email.errors && (
                  <div id={`${fields.email.id}-error`}>
                    <ErrorList errors={fields.email.errors} />
                  </div>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input type="password" {...conform.input(fields.password)} />
                {fields.password.errors && (
                  <div id={`${fields.password.id}-error`}>
                    <ErrorList errors={fields.password.errors} />
                  </div>
                )}
              </div>

              <div className="flex justify-between">
                <CheckboxField
                  labelProps={{
                    htmlFor: fields.remember.id,
                    children: "Remember me",
                  }}
                  buttonProps={conform.input(fields.remember, {
                    type: "checkbox",
                  })}
                  errors={fields.remember.errors}
                />
                <div>
                  <Button className="h-auto p-0" variant="link" asChild>
                    <Link
                      to="/forgot-password"
                      className="inline-block text-sm font-semibold"
                    >
                      Forgot password?
                    </Link>
                  </Button>
                </div>
              </div>

              <input
                {...conform.input(fields.redirectTo, { type: "hidden" })}
              />
              <ErrorList errors={form.errors} id={form.errorId} />

              <div className="flex items-center justify-between gap-6 pt-3">
                <Button
                  className="w-full"
                  // status={isPending ? "pending" : actionData?.status ?? "idle"}
                  type="submit"
                  disabled={isPending}
                >
                  Log in
                </Button>
              </div>
            </Form>
            {/* <ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
              {providerNames.map((providerName) => (
                <li key={providerName}>
                  <ProviderConnectionForm
                    type="Login"
                    providerName={providerName}
                    redirectTo={redirectTo}
                  />
                </li>
              ))}
            </ul> */}
            <div className="flex items-center justify-center gap-2 pt-6">
              <span className="text-muted-foreground">New here?</span>
              <Button className="h-auto p-0" variant="link" asChild>
                <Link
                  to={
                    redirectTo
                      ? `/signup?${encodeURIComponent(redirectTo)}`
                      : "/signup"
                  }
                >
                  Create an account
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
