import type { Submission } from "@conform-to/react";
import { conform, useForm } from "@conform-to/react";
import { getFieldsetConstraint, parse } from "@conform-to/zod";
import {
  Form,
  useActionData,
  useLoaderData,
  useSearchParams,
} from "@remix-run/react";
import type { DataFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import z from "zod";
import { prisma } from "~/utils/db.server";
import { ErrorList, Field } from "~/components/ui/Forms";
import { useIsPending } from "~/utils/misc";
import { getDomainUrl, redirectWithToast } from "~/utils/misc.server";
import {
  handleVerification as handleLoginTwoFactorVerification,
  shouldRequestTwoFA,
} from "./login";
import { handleVerification as handleOnboardingVerification } from "./onboarding";
import { handleVerification as handleResetPasswordVerification } from "./reset-password";
import { generateTOTP, verifyTOTP } from "~/utils/totp.server";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { validateCSRF } from "~/utils/csrf.server";
import { twoFAVerificationType } from "../two-factor";
import { Button } from "~/components/ui/Button";
import { requireAuth } from "~/utils/auth-utils/requireAuth.server";

export const codeQueryParam = "code";
export const targetQueryParam = "target";
export const typeQueryParam = "type";
export const redirectToQueryParam = "redirectTo";
export const rememberQueryParam = "remember";
const types = ["onboarding", "reset-password", "change-email", "2fa"] as const;
const VerificationTypeSchema = z.enum(types);
export type VerificationTypes = z.infer<typeof VerificationTypeSchema>;

const VerifySchema = z.object({
  [codeQueryParam]: z.string().min(6).max(6),
  [typeQueryParam]: VerificationTypeSchema,
  [targetQueryParam]: z.string(),
  [redirectToQueryParam]: z.string().optional(),
  [rememberQueryParam]: z.boolean().optional().optional(),
});

export async function loader({ request }: DataFunctionArgs) {
  const params = new URL(request.url).searchParams;
  if (!params.has(codeQueryParam)) {
    // we don't want to show an error message on page load if the otp hasn't be
    // prefilled in yet, so we'll send a response with an empty submission.
    return json({
      status: "idle",
      submission: {
        intent: "",
        payload: Object.fromEntries(params),
        error: {},
      },
    } as const);
  }
  return validateRequest(request, params);
}

export function getRedirectToUrl({
  request,
  type,
  target,
  redirectTo,
}: {
  request: Request;
  type: VerificationTypes;
  target: string;
  redirectTo?: string;
}) {
  const redirectToUrl = new URL(`${getDomainUrl(request)}/verify`);
  redirectToUrl.searchParams.set(typeQueryParam, type);
  redirectToUrl.searchParams.set(targetQueryParam, target);
  if (redirectTo) {
    redirectToUrl.searchParams.set(redirectToQueryParam, redirectTo);
  }
  return redirectToUrl;
}

export async function prepareVerification({
  period,
  request,
  type,
  target,
}: {
  period: number;
  request: Request;
  type: VerificationTypes;
  target: string;
}) {
  const verifyUrl = getRedirectToUrl({ request, type, target });
  const redirectTo = new URL(verifyUrl.toString());

  const { otp, ...verificationConfig } = generateTOTP({
    algorithm: "SHA256",
    period,
  });
  const verificationData = {
    type,
    target,
    ...verificationConfig,
    expiresAt: new Date(Date.now() + verificationConfig.period * 1000),
  };
  await prisma.verification.upsert({
    where: { target_type: { target, type } },
    create: verificationData,
    update: verificationData,
  });

  // add the otp to the url we'll email the user.
  verifyUrl.searchParams.set(codeQueryParam, otp);

  return { otp, redirectTo, verifyUrl };
}

export const twoFAVerifyVerificationType = "2fa-verify";

export async function requireRecentVerification(request: Request) {
  const { userId } = await requireAuth(request);
  const shouldReverify = await shouldRequestTwoFA(request);
  if (shouldReverify) {
    const reqUrl = new URL(request.url);
    const redirectUrl = getRedirectToUrl({
      request,
      target: userId,
      type: twoFAVerificationType,
      redirectTo: reqUrl.pathname + reqUrl.search,
    });
    throw await redirectWithToast(
      request,
      redirectUrl.toString(),
      "Please reverify your account before proceeding",
      null,
    );
  }
}

export async function isCodeValid({
  code,
  type,
  target,
}: {
  code: string;
  type: VerificationTypes | typeof twoFAVerifyVerificationType;
  target: string;
}) {
  const verification = await prisma.verification.findUnique({
    where: {
      target_type: { target, type },
      OR: [{ expiresAt: { gt: new Date() } }, { expiresAt: null }],
    },
    select: { algorithm: true, secret: true, period: true },
  });
  if (!verification) return false;
  const result = verifyTOTP({
    otp: code,
    secret: verification.secret,
    algorithm: verification.algorithm,
    period: verification.period,
  });
  if (!result) return false;

  return true;
}

export type VerifyFunctionArgs = {
  request: Request;
  submission: Submission<z.infer<typeof VerifySchema>>;
  body: FormData | URLSearchParams;
};

async function validateRequest(
  request: Request,
  body: URLSearchParams | FormData,
) {
  const submission = await parse(body, {
    schema: () =>
      VerifySchema.superRefine(async (data, ctx) => {
        const codeIsValid = await isCodeValid({
          code: data[codeQueryParam],
          type: data[typeQueryParam],
          target: data[targetQueryParam],
        });
        if (!codeIsValid) {
          ctx.addIssue({
            path: ["code"],
            code: z.ZodIssueCode.custom,
            message: `Invalid code`,
          });
          return;
        }
      }),
    async: true,
  });

  if (submission.intent !== "submit") {
    return json({ status: "idle", submission } as const);
  }
  if (!submission.value) {
    return json({ status: "error", submission } as const, { status: 400 });
  }

  const { value: submissionValue } = submission;

  async function deleteVerification() {
    await prisma.verification.delete({
      where: {
        target_type: {
          type: submissionValue[typeQueryParam],
          target: submissionValue[targetQueryParam],
        },
      },
    });
  }

  switch (submissionValue[typeQueryParam]) {
    case "reset-password": {
      await deleteVerification();
      return handleResetPasswordVerification({ request, body, submission });
    }
    case "onboarding": {
      await deleteVerification();
      return handleOnboardingVerification({ request, body, submission });
    }
    case "2fa": {
      return handleLoginTwoFactorVerification({ request, body, submission });
    }
    case "change-email": {
      // NO SUPPORT YET
      return redirect("/");
    }
  }
}

export async function action({ request }: DataFunctionArgs) {
  const formData = await request.formData();

  // 🐨 validate the CSRF token
  await validateCSRF(formData, request.headers);

  return validateRequest(request, formData);
}

export default function VerifyRoute() {
  const data = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  const remember = searchParams.get(rememberQueryParam);
  const isPending = useIsPending();
  const actionData = useActionData<typeof action>();
  const type = VerificationTypeSchema.parse(searchParams.get(typeQueryParam));

  const checkEmail = (
    <div className="flex flex-col gap-3 text-center">
      <p className="text-2xl font-semibold lg:text-4xl">Check your email</p>
      <p className="text-base text-muted-foreground">
        We've sent you a code to verify your email address.
      </p>
    </div>
  );

  const headings: Record<VerificationTypes, React.ReactNode> = {
    onboarding: checkEmail,
    "reset-password": checkEmail,
    "change-email": checkEmail,
    "2fa": (
      <>
        <h1 className="text-h1">Check your 2FA app</h1>
        <p className="text-body-md mt-3 text-muted-foreground">
          Please enter your 2FA code to verify your identity.
        </p>
      </>
    ),
  };

  const [form, fields] = useForm({
    id: "verify-form",
    constraint: getFieldsetConstraint(VerifySchema),
    lastSubmission: actionData?.submission ?? data.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: VerifySchema });
    },
    defaultValue: {
      code: searchParams.get(codeQueryParam) ?? "",
      type,
      target: searchParams.get(targetQueryParam) ?? "",
      redirectTo: searchParams.get(redirectToQueryParam) ?? "",
    },
  });

  return (
    <div className="flex min-h-full flex-col justify-center p-8 pb-32 pt-20">
      <div className="mx-auto flex w-full max-w-md flex-col gap-8 lg:gap-12">
        <div className="flex flex-col gap-3 text-center">{headings[type]}</div>

        <div className="mx-auto flex w-72 max-w-full flex-col justify-center gap-1">
          <div>
            <ErrorList errors={form.errors} id={form.errorId} />
          </div>
          <div className="flex w-full gap-2">
            <Form method="POST" {...form.props} className="flex-1 space-y-6">
              <AuthenticityTokenInput />
              <Field
                labelProps={{
                  htmlFor: fields[codeQueryParam].id,
                  children: "Code",
                }}
                inputProps={conform.input(fields[codeQueryParam])}
                errors={fields[codeQueryParam].errors}
              />
              <input
                {...conform.input(fields[typeQueryParam], { type: "hidden" })}
              />
              <input
                {...conform.input(fields[targetQueryParam], { type: "hidden" })}
              />
              <input
                {...conform.input(fields[redirectToQueryParam], {
                  type: "hidden",
                })}
              />
              {remember === "on" ? (
                <input name={rememberQueryParam} value="on" type="hidden" />
              ) : null}
              <Button type="submit" disabled={isPending} className="w-full">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
